import './ticketCriacao.css';
import { useCallback, useContext, useEffect, useState } from "react";
import Button from "../../../components/button/button";
import Voltar from "../../../components/voltar/voltar";
import MovideskService from "../../../services/MovideskService";
import useFeedback from "../../../hooks/use-feedback";
import Search from "../../../components/search/search";
import SessaoUsuarioContext from "../../../contexts/SessaoUsuarioContext";
import { CpfOuCnpjFormatter } from "../../../helpers/formatters/CpfOuCnpjFormatter";
import moment from 'moment';
import Spinner from "../../../components/spinner/spinner";
import Feedback from "../../../components/feedback/feedback";
import InicializaTicket, { Equipe, Justificativa, Status } from "../../../models/Ticket";
import Upload from "../../../components/upload-image/upload";
import { useNavigate } from 'react-router-dom';
import Select from '../../../components/select/select';

const TicketNovo = () => {
  const navigate = useNavigate();
  const { sessao } = useContext(SessaoUsuarioContext);
  const { feedbacks, adicionarFeedbackDeSucesso, adicionarFeedbackDeErro, removerFeedback } = useFeedback();
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState(InicializaTicket);
  const [images, setImages] = useState<string[]>([]);
  const [modulos, setModulos] = useState<string[]>([]);
  const [modulo, setModulo] = useState<string>();

  const cnpjLicenciado = new CpfOuCnpjFormatter(sessao?.licenciadoCnpj || '').format();
  const data = moment();
  const dataAtual = data.format('DD/MM/YYYY');

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = useCallback(async (): Promise<void> => {
    setLoading(true)    
    try {
      formState.imagens = images
      formState.modulo = (modulo || '').toLowerCase() === "pessoal" ? 1 : 2
      await MovideskService.CriaSolicitacao(formState)
      adicionarFeedbackDeSucesso("Solicitação criada com sucesso!");
    } catch (error) {
      var listaDeErros = (error as any)['erro']['erros'];
      setLoading(false)
      adicionarFeedbackDeErro((error as Error).message + '');
      listaDeErros && listaDeErros.forEach((result: any) => {
        adicionarFeedbackDeErro(result.mensagem);
      })
    }
    setLoading(false)
  }, [modulo, adicionarFeedbackDeSucesso, adicionarFeedbackDeErro, formState, images]);

  const handleSelectedValue = (selectedValue: any) => {
    setFormState(prevState => ({
      ...prevState,
      solicitanteId: selectedValue['id'].toString(),
      solicitante: selectedValue,
    }));
  };

  const UploadImagens = (values: any) => setImages(values)

  const obtemModuloParaSolicitacao = useCallback((modulo: string) => {
    if (modulo === "Pessoal") {
      formState.equipeResponsavel = Equipe.Pessoal;
      formState.status = Status.Pessoal;
      formState.justificativa = Justificativa.Pessoal
    }
    setModulo(modulo)
  },[formState])

  useEffect(() => {
    var modulos = sessao?.modulosLiberados.map(x => x.nome) ?? [];
    if (modulos?.length !== 1) {
      modulos?.unshift("Escolha um módulo")
    }

    obtemModuloParaSolicitacao(modulos[0]);
    setModulos(modulos)
  }, [obtemModuloParaSolicitacao, sessao?.modulosLiberados])


  return (
    <>
      <h1 className="top-title"><Voltar />Sugestão de Melhorias</h1>
      <div className="container">
        <div className="ticket-body">
          <div className="grid-inline">
            <div className="grid-collum" style={{ width: '40%' }}>
              <label className="company-label">CNPJ do Licenciado</label>
              <input
                className="text-input"
                type="text"
                disabled
                value={cnpjLicenciado}
              />

            </div>
            <div className="grid-collum" style={{ width: '40%' }}>
              <label className="company-label">Data de Abertura </label>
              <input
                className="text-input"
                type="text"
                disabled
                value={dataAtual.toString()}
              />
            </div>
          </div>
          <div className="grid-inline">
            <div className="grid-collum" style={{ width: '100%' }}>
              <label className="company-label">Titulo *</label>
              <input
                className="text-input"
                type="text"
                name="titulo"
                onChange={x => handleChange(x)}
              />
            </div>
          </div>

          <div className="grid-inline">
            <div className="grid-collum" style={{ width: '40%', marginRight: '5px' }}>
              <label className="company-label"> Solicitante *</label>
              <Search
                classes="text-input"
                style={{ width: '100%' }}
                onSelect={handleSelectedValue}
                values="nome"
                fetchService={MovideskService.ObtemListaDeSolicitantes} />
            </div>
            <div className="grid-collum" style={{ width: '40%' }}>
              <label className="company-label"> Módulos *</label>
              <Select options={modulos} onRowClick={x => obtemModuloParaSolicitacao(x)} />
            </div>
          </div>

          <div className="grid-inline">
            <div className="grid-collum" style={{ width: '100%' }}>
              <label className="company-label"> Qual problema você deseja resolver ? *</label>
              <textarea
                name="problema"
                onChange={x => handleChange(x)}
              />
            </div>
          </div>
          <div className="grid-inline">
            <div className="grid-collum" style={{ width: '100%' }}>
              <label className="company-label"> Alguma proposta de solução para o problema ? *</label>
              <textarea
                name="propostaDeSolucao"
                onChange={x => handleChange(x)}
              />
            </div>
          </div>
          <div className="grid-inline">
            <div className="grid-collum" style={{ width: '100%' }}>
              <label className="company-label"> Quanto custa (financeira ou processualmente)para seu negócio se não fizermos isso ?</label>
              <textarea
                name="custoSeNaoImplementado"
                onChange={x => handleChange(x)}
              />
            </div>
          </div>
          <div className="grid-inline">
            <div className="grid-collum" style={{ width: '100%' }}>
              <label className="company-label"> Qual o embasamento (anexar legislação, ACT/CCT e demais documentos) ?</label>
              <textarea
                name="embasamento"
                onChange={x => handleChange(x)}
              />
            </div>
          </div>
          <div className="grid-inline">
            <div className="grid-collum" style={{ width: '100%' }}>
              <label className="company-label"> Anexos </label>
              <div style={{ padding: '10px 20px 20px 0' }}>
                <Upload onImagesChange={i => UploadImagens(i)} />
              </div>
            </div>
          </div>
        </div>
        <Button
          text="Salvar"
          style={{ margin: 0 }}
          classes="submit-button"
          onClick={() => handleSubmit()}>
        </Button>
        <Button
          text="Voltar"
          style={{ marginLeft: '10px' }}
          classes="submit-button"
          onClick={() => navigate('/ticket')}>
        </Button>
        {loading && <div className='loading'>
          <Spinner size={170} />
        </div>}
        <Feedback feedbacks={feedbacks} remover={removerFeedback} />
      </div >
    </>
  );
}

export default TicketNovo;