import { CnpjFormatter } from "./CnpjFormatter";
import { CpfFormatter } from "./CpfFormatter";
import { Formatter } from "./Formatter";

export class CpfOuCnpjFormatter implements Formatter {
  private readonly identificadorFiscal: Formatter;

  constructor(identificador: string) {
    this.identificadorFiscal = this.instanciarFormatter(identificador);
  }

  private readonly instanciarFormatter = (identificador: string): Formatter => {
    switch (identificador.length) {
      case 11: return new CpfFormatter(identificador);
      case 14: return new CnpjFormatter(identificador);
      default: return new DesconhecidoFormatter(identificador);
    }
  }

  public readonly format = () => this.identificadorFiscal.format();

}

class DesconhecidoFormatter implements Formatter {
  constructor(private readonly identificador: string) { }

  public readonly format = () => this.identificador;
}