import { Hub } from 'aws-amplify/utils';
import { Amplify } from 'aws-amplify';
import AppConstants from './app-constants';

const { Cloud } = AppConstants;
const awsConfig = {
  region: Cloud.COGNITO_APP_REGION,
  userPoolId: Cloud.COGNITO_APP_USER_POOL_ID,
  userPoolClientId: Cloud.COGNITO_APP_CLIENT_ID,
  identityPoolId: Cloud.COGNITO_APP_IDENTITY_ID,
  oauth: {
    domain: 'fortesbi.auth.us-east-1.amazoncognito.com',
    redirectSignIn: AppConstants.appBaseUrl,
    redirectSignOut: AppConstants.appBaseUrl,
    responseType: 'code'
  }
}

Amplify.configure({
  Auth: { Cognito:awsConfig }
})

Hub.listen('auth', ({ payload: { event, message } }) => {
  switch (event) {
    case 'signedIn':
      console.log('Authenticated!');
      break;
    case 'customOAuthState':
      console.log('customOAuthState!');
      break;
    case 'signedOut':
      window.location.assign('/')
      console.log('user have been signedOut successfully.');
      break;
    case 'tokenRefresh':
      console.log('auth tokens have been refreshed.');
      break;
    case 'tokenRefresh_failure':
      window.location.assign('/')
      console.log('failure while refreshing auth tokens.');
      break;
    case 'signInWithRedirect':
      console.log('signInWithRedirect API has successfully been resolved.');
      break;
    case 'signInWithRedirect_failure':
      console.log('Error', event, message);
      break;
  }
});