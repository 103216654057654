class LocalStorageService {
  static getItem<T>(key: string): T | undefined {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : undefined;
    } catch (error) {
      console.error(`Erro ao recuperar "${key}" do localStorage: ${error}`);
      return undefined;
    }
  }

  static setItem<T>(key: string, value: T) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(`Erro ao armazenar "${key}: ${value}" no localStorage: ${error}`);
    }
  }

  static removeItem(key: string) {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(`Erro ao remover "${key}" do localStorage: ${error}`);
    }
  }
}

export default LocalStorageService;
