import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faLaptopHouse, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { HTMLAttributes } from 'react';
import { useNavigate } from 'react-router-dom';
import LinksMovidesk from './constante';
import './botoes.css';


interface MenuMovideskProps extends HTMLAttributes<HTMLElement> { }

const MenuMovidesk = ({ ...rest }: MenuMovideskProps) => {
  const integracaoMovidesk = (value: string) => window.open(value.toString(), '_blank');
  const navigate = useNavigate();
  return (
    <>
      <div {...rest} className='menu-movidesk'>
        <button className='layout-botoes' onClick={() => integracaoMovidesk(LinksMovidesk.CHAT)}>
          <label className='titulo'>Chat</label>
          <FontAwesomeIcon className='layout-botoes__img' icon={faComments} />
        </button>
        <button className='layout-botoes' onClick={() => integracaoMovidesk(LinksMovidesk.CENTRA_DE_AJUDA)}>
          <label className='titulo' style={{ right: '285px' }}>Central de ajuda</label>
          <FontAwesomeIcon className='layout-botoes__img' icon={faLaptopHouse} />
        </button>
        <button className='layout-botoes' onClick={() => navigate('/ticket')}>
          <label className='titulo' style={{ right: '250px' }}>Enviar Sugestão</label>
          <FontAwesomeIcon className='layout-botoes__img' style={{width:"23px"}} icon={faPaperPlane} />
        </button>
      </div>
    </>
  )
}

export default MenuMovidesk;