import { createContext } from "react";
import { Ambiente } from "../models/Ambiente";
import { FeedbackStructure } from "../hooks/use-feedback";

interface AppContextProps {
  estaAtualizando: boolean;
  setAtualizando: (updating: boolean) => void;
  ambiente: Ambiente;
  setAmbiente: (ambiente: Ambiente) => void;
  feedback: FeedbackStructure;
}

const AppContext = createContext<AppContextProps>({
  estaAtualizando: false,
  setAtualizando: () => { },
  ambiente: {} as Ambiente,
  setAmbiente: () => { },
  feedback: {} as FeedbackStructure
});

export default AppContext;