import { Parametrizacao } from "../models/Parametrizacao";
import FBIHttp from "./FBIHttp";

export interface ObtencaoDoFaturamentoDaEmpresaOutput {
  empresaId: string,
  faturamentosAnuais: FaturamentoAnualOutput[],
}

export interface FaturamentoAnualOutput {
  ano: number,
  faturamentosMensais: FaturamentoMensalOutput[],
}

export interface FaturamentoMensalOutput {
  mes: string,
  valor: number
}

export interface InclusaoDoFaturamentoDaEmpresaInput {
  empresaId: string,
  parametrizacao: Parametrizacao | null,
  faturamentosAnuais: FaturamentoAnualInput[]
}

export interface FaturamentoAnualInput {
  ano: number,
  faturamentosMensais: FaturamentoMensalInput[]
}

export interface FaturamentoMensalInput {
  mes: string,
  valor: number
}

export interface InclusaoDoFaturamentoDaEmpresaOutput {

}

class FaturamentoService {
  private static readonly baseUrl = '/api/bi/Faturamento';

  public static obterFaturamentoDaEmpresa = async (empresaId?: string | null): Promise<ObtencaoDoFaturamentoDaEmpresaOutput> => FBIHttp.get(`${this.baseUrl}/${empresaId}`);

  public static salvarFaturamentoDaEmpresa = async (input: InclusaoDoFaturamentoDaEmpresaInput): Promise<InclusaoDoFaturamentoDaEmpresaOutput> => FBIHttp.post(this.baseUrl, input);
}

export default FaturamentoService;