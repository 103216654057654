import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import App from './app/App';
import { ToggleMenuProvider } from './components/menu/menuUtils';
import { Authenticator, translations } from '@aws-amplify/ui-react';
import { I18n } from '@aws-amplify/core';
import Login from './pages/login/login';

import './config/auth-config'

import './index.css';

I18n.putVocabularies(translations);
I18n.setLanguage('pt-BR');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <Authenticator
      className='authenticator-content'
      hideSignUp={true}
      formFields={Login.LoginFormFields}
      components={Login.LoginComponents}
    >
    <ToggleMenuProvider>
      <App />
    </ToggleMenuProvider>
    </Authenticator>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
