const CloudConstants = {
  COGNITO_APP_REGION: "us-east-1",
  COGNITO_APP_USER_POOL_ID: "us-east-1_umORLhIU4",
  COGNITO_APP_CLIENT_ID: "1sqgocjhivkbhpo0rkp029gmg3",
  COGNITO_APP_IDENTITY_ID: "us-east-1:664dee85-b7f0-42d9-bc95-ff24772bafe3"
};

const DevelopmentConstants = {
  apiBaseUrl: "https://localhost:7115",
  appBaseUrl: "http://localhost:3000"
};

const StagingConstants = {
  apiBaseUrl: "https://api.hom.fortesbi.com.br",
  appBaseUrl: "https://app.hom.fortesbi.com.br"
};

const ProductionConstants = {
  apiBaseUrl: "https://api.fortesbi.com.br",
  appBaseUrl: "https://app.fortesbi.com.br"
};

const AppConstants = {
  Cloud: {...CloudConstants},
  Development: DevelopmentConstants,
  Staging: StagingConstants,
  Production: ProductionConstants,
  apiBaseUrl: "",
  appBaseUrl: ""
};

const buildEnv = (() => {
  const host = window.location.host;
  
  if (host.indexOf("hom.fortesbi.com.br") > -1)
    return "Staging";

  if (host.indexOf("fortesbi.com.br") > -1)
    return "Production";

  return 'Development';
})();

AppConstants.apiBaseUrl = AppConstants[buildEnv].apiBaseUrl;
AppConstants.appBaseUrl = AppConstants[buildEnv].appBaseUrl;

export default AppConstants;