import { Link } from "react-router-dom";
import "./voltar.css";

interface Props {
  caminho?: string
}

const Voltar = ({ caminho }: Props) => {
  return <Link className="volta" to={`/${caminho || ''}`} title="Voltar">&#10094; </Link>;
}

export default Voltar;