import './spinner.css'

interface SpinnerProps {
  size?: number
}

const Spinner = (props: SpinnerProps) => {
  const size = props.size || 30;
  const borderWidth = size < 20 ? 3 : 4;

  return <div className="spinner" style={{ width: size, height: size, borderWidth }}></div >;
}

export default Spinner;