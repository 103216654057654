import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import AppContext from "../../contexts/AppContext";
import SessaoUsuarioContext from "../../contexts/SessaoUsuarioContext";
import { CpfOuCnpjFormatter } from "../../helpers/formatters/CpfOuCnpjFormatter";
import UsuarioService, { UsuarioDoLicenciadoOutput } from "../../services/UsuarioService";
import UsuarioLocalStorageService from "../../services/UsuarioLocalStorageService";

import './selecao-de-licenciado.css';
import Spinner from "../../components/spinner/spinner";
import useSignOut from "../../hooks/use-sign-out";


const SelecaoDeLicenciado = () => {
  const navigate = useNavigate();
  const signOutCallback = useSignOut();
  const { setSessao } = useContext(SessaoUsuarioContext);
  const { setAtualizando } = useContext(AppContext)
  const [usuariosLicenciados, setUsuariosLicenciados] = useState<UsuarioDoLicenciadoOutput[]>([]);
  const carregando = useMemo(() => usuariosLicenciados.length === 0, [usuariosLicenciados]);

  const selecaoDeLicenciadoCallback = useCallback((usuarioLicenciadoOutput: UsuarioDoLicenciadoOutput) => {
    UsuarioLocalStorageService.atribuir(usuarioLicenciadoOutput);
    setSessao!(usuarioLicenciadoOutput);
    setAtualizando(true);
    navigate('/');
  }, [setAtualizando, setSessao, navigate]);

  useEffect(() => {
    (async () => {
      const licenciados = await UsuarioService.obterInformacoesDoEmailDaSessao();
      if (licenciados.length === 1
        && !licenciados[0].temLicencaFortesPessoal
        && !licenciados[0].temLicencaFortesRh
        && !licenciados[0].temLicencaFortesFinanceiro) {
        signOutCallback();
      }
      setUsuariosLicenciados(licenciados);
    })();
  }, [setSessao, signOutCallback]);

  useEffect(() => {
    (async () => {
      if (usuariosLicenciados.length === 1) {
        selecaoDeLicenciadoCallback(usuariosLicenciados[0]);
      }
    })();
  }, [selecaoDeLicenciadoCallback, navigate, setSessao, usuariosLicenciados]);

  return (
    <>
      <h1 className="top-title">Seleção de Licenciado</h1>
      <div className='content-container' style={{ maxWidth: '400px', textAlign: 'center' }}>
        <label className="selecionar-licenciado-label">Selecione um licenciado abaixo:</label>
        <ul className="selecionar-licenciado-itens">
          {carregando &&
            <li className="selecionar-licenciado-itens-carregando">
              <Spinner />
            </li>
          }
          {usuariosLicenciados.map(usuarioLicenciado => {
            return (
              <li
                className="selecionar-licenciado-item"
                key={usuarioLicenciado.licenciadoId}
                onClick={() => selecaoDeLicenciadoCallback(usuarioLicenciado)}>
                {new CpfOuCnpjFormatter(usuarioLicenciado.licenciadoCnpj).format()}
              </li>
            )
          })}
        </ul>
      </div >
    </>
  );
}

export default SelecaoDeLicenciado