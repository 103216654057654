import { useContext, useMemo } from "react";
import SessaoUsuarioContext from "../../contexts/SessaoUsuarioContext";
import { CpfOuCnpjFormatter } from "../../helpers/formatters/CpfOuCnpjFormatter";

import { NavLink } from "react-router-dom";
import './usuario-logado.css';

const UsuarioLogado = () => {
  const { sessao, usuariosLicenciados } = useContext(SessaoUsuarioContext);

  const cpfOuCnpjFormatado = useMemo(() => {
    return new CpfOuCnpjFormatter(sessao?.licenciadoCnpj || '').format();
  }, [sessao]);

  const htmlDados =
    <>
      <div className="dados">{sessao?.nome}</div>
      <div className="dados extra" title={sessao?.email || ""}><b>E-mail:</b> {sessao?.email}</div>
      <div className="dados extra"><b>Licenciado:</b> {cpfOuCnpjFormatado}</div>
    </>

  const htmlDadosComLink = <NavLink to={"/selecionar-licenciado"}> {htmlDados} </NavLink>

  return (
    <div className='usuario-logado'>
      {usuariosLicenciados.length > 1 ? htmlDadosComLink : htmlDados}
    </div>
  );
}

export default UsuarioLogado