import { View, useTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './login.css';

import { I18n } from '@aws-amplify/core';

I18n.putVocabularies({
  'pt-BR': {
    'Username': 'Email',
    'Enter your Password': 'Digite sua senha',
    'Enter your username': 'Digite seu email',
    'Please confirm your Password':'Por favor confirme sua senha',
    'Incorrect username or password.': 'Usuário ou senha está errado.',
    'Reset Password': 'Alterar Senha',
    'Code *': 'Código *',
    'New Password': 'Nova Senha',
    'Password must have at least 8 characters': 'Senha deve ter pelo menos 8 caracteres',
    'Your passwords must match': 'As duas senhas digitadas devem ser iguais',
    'Invalid verification code provided, please try again.': 'Código de verificação inválido, tente novamente.',
    'Invalid session for the user, session is expired.':'Sessão inválida para o usuário, a sessão expirou.',
    'Password does not conform to policy: Password must have lowercase characters': 'A senha não está em conformidade com a política: A senha deve conter caracteres minúsculos',
    'Password does not conform to policy: Password must have uppercase characters': 'A senha não está em conformidade com a política: A senha deve conter caracteres maiúsculos',
    'Attempt limit exceeded, please try after some time.': 'Limite de tentativas excedido. Tente depois de algum tempo.'
  },
});

class Login {
  static LoginFormFields = {
    signIn: {
      username: {
        placeholder: 'nome@host.com',
        isRequired: true
      },
      password: {
        isRequired: true
      },
    },
  }

  static LoginComponents = {
    Header() {
      const { tokens } = useTheme();
      return (
        <>
          <View textAlign="center" padding={tokens.space.large}>
            <img
              alt="Logo"
              src="logo.png"
              style={{
                width: '100%',
                height: 'auto',
              }}
            />
          </View>
        </>
      );
    },
  }
}

export default Login;