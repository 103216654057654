import LocalStorageService from "./LocalStorageService";
import { UsuarioDoLicenciadoOutput } from "./UsuarioService";

class UsuarioLocalStorageService {
  private static readonly USUARIO_LICENCIADO_STORAGE_KEY = 'usuario-licenciado';

  public static obter = (): UsuarioDoLicenciadoOutput | undefined => LocalStorageService.getItem(this.USUARIO_LICENCIADO_STORAGE_KEY);
  public static remover = (): void => LocalStorageService.removeItem(this.USUARIO_LICENCIADO_STORAGE_KEY);
  public static atribuir = (usuarioLicenciado: UsuarioDoLicenciadoOutput): void => LocalStorageService.setItem(this.USUARIO_LICENCIADO_STORAGE_KEY, usuarioLicenciado);
}

export default UsuarioLocalStorageService;
