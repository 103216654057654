import AppConstants from "../config/app-constants";
import ErroDeNegocio from "../errors/ErroDeNegocio";
import TokenService from "./TokenService";
import UsuarioLocalStorageService from "./UsuarioLocalStorageService";

class FBIHttp {

  private static readonly baseURL = AppConstants.apiBaseUrl;

  static readonly delete = async(url: string): Promise<any> => {
    return FBIHttp.fetch(url, 'DELETE');
  }

  static readonly get = async (url: string, incluiLicenciado: boolean = true): Promise<any> => {
    return FBIHttp.fetch(url, 'GET', undefined, incluiLicenciado);
  }

  static readonly post = async (url: string, body?: any) => {
    return FBIHttp.fetch(url, 'POST', body);
  }

  private static readonly fetch = async (url: string, method: 'POST' | 'GET' | 'DELETE', body?: any, incluiLicenciado: boolean = true) => {
    const fetchParams = await FBIHttp.fetchParams(method, body, incluiLicenciado);

    const response: Response = await this.doFetch(`${this.baseURL}${url}`, fetchParams);

    if (!response.ok) await FBIHttpErrorMapper.map(response);
    return await response.json();
  }

  private static readonly fetchParams = async (method: string, body?: any, incluiLicenciado?: boolean) => {
    const headers = await this.headers(body, incluiLicenciado);

    let fetchParams: any = { method, headers };

    if (body) {
      const bodyContent = this.isFormData(body) ? body : JSON.stringify(body);
      fetchParams = { ...fetchParams, body: bodyContent };
    }

    return fetchParams;
  }

  private static readonly isFormData = (body: any | undefined) => body instanceof FormData;

  private static readonly headers = async (body: any | undefined, incluiLicenciado?: boolean): Promise<Record<string, string>> => {
    const token = await TokenService.obter();
    const authorizationHeader = token ? { Authorization: `Bearer ${token}` } : undefined;

    const contentTypeHeader = this.isFormData(body) ? undefined : { 'Content-Type': 'application/json' };

    const licenciadoId = (incluiLicenciado ? UsuarioLocalStorageService.obter()?.licenciadoId : undefined);
    const licenciadoIdHeader = licenciadoId ? { 'X-Licenciado-Id': licenciadoId } : undefined;

    let headers = {};
    if (authorizationHeader) headers = { ...headers, ...authorizationHeader }
    if (contentTypeHeader) headers = { ...headers, ...contentTypeHeader }
    if (licenciadoIdHeader) headers = { ...headers, ...licenciadoIdHeader }

    return headers;
  }

  private static readonly doFetch = async (url: string, params: Record<string, string>): Promise<any> => {
    try { return await fetch(url, params); }
    catch (error) { throw new Error("Não foi possível se conectar ao servidor. Por favor verifique sua conexão com a Internet e tente novamente."); }
  }

}

class FBIHttpErrorMapper {

  static readonly map = async (response: Response) => {

    const erroString = await response.text();
    const mensagemPadrao = 'Algo inesperado ocorreu. Tente executar novamente em alguns instantes. Caso o problema continue, por favor entre em contato com o suporte.';
    switch (response.status) {
      case 400: throw new ErroDeNegocio(JSON.parse(erroString));
      case 401: throw new Error('Sua sessão expirou. Por favor atualize a tela e tente novamente.');
      case 403: throw new Error('Você não tem permissão para executar essa operação.');
      case 500: throw new Error(mensagemPadrao);
      default: throw new Error(mensagemPadrao);
    }

  }

}

export default FBIHttp;