import { createContext, useState, useContext } from 'react';
import { useCallback } from 'react';

const ToggleMenuContext = createContext();

export const ToggleMenuProvider = ({ children }) => {
 const [isOpen, setIsOpen] = useState(false);

 const toggleMenu = useCallback((value) => {
  value === false ? setIsOpen(value) : setIsOpen((prevIsOpen) => !prevIsOpen);
 }, [setIsOpen]);

 return (
  <ToggleMenuContext.Provider value={{ isOpen, toggleMenu }}>
    {children}
  </ToggleMenuContext.Provider>
 );
};

export const useToggleMenu = () => useContext(ToggleMenuContext);