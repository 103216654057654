import EmpresaService, { EmpresaInput, EmpresaOutput } from "../../../services/EmpresaService";
import { useCallback, useState } from "react";
import EmpresaForm from "../empresa-formulario/empresa-formulario";
import ErroDeNegocio from "../../../errors/ErroDeNegocio";
import useAppFeedback from "../../../hooks/use-app-feedback";

import './empresa-edicao.css';

interface EmpresaEdicaoProps {
  empresaParaAtualizar: EmpresaOutput;
  clickCancelar?: () => void;
}

const EmpresaEdicao = ({ empresaParaAtualizar, clickCancelar }: EmpresaEdicaoProps) => {
  const [loading, setLoading] = useState(false);
  const { adicionarFeedbackDeSucesso, adicionarFeedbackDeErro, limparFeedbacks } = useAppFeedback();

  const atualizarEmpresa = useCallback((async (empresa: EmpresaInput): Promise<boolean> => {
    limparFeedbacks();
    setLoading(true)

    try {
      await EmpresaService.salvarEmpresa(empresa);
      adicionarFeedbackDeSucesso("Empresa Atualizada com sucesso!");
    } catch (error) {
      const message = (error as ErroDeNegocio)?.mensagem || (error as Error).message;
      adicionarFeedbackDeErro(message);
    }
    setLoading(false)

    return false;
  }), [adicionarFeedbackDeErro, adicionarFeedbackDeSucesso, limparFeedbacks]);

  return (
    <>
      <EmpresaForm
        loading={loading}
        titulo="Alterar Empresa"
        empresaInput={empresaParaAtualizar}
        classes="empresa-edicao"
        acao={{
          onClickAcao: atualizarEmpresa,
          texto: 'Salvar'
        }}
        cancelarAcao={{
          onClickCancelaAcao: clickCancelar,
          texto: 'Cancelar'
        }} />
    </>
  )
}

export default EmpresaEdicao;