import { ButtonHTMLAttributes } from 'react';
import './button.css';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string | React.ReactElement
  classes?: string
}

const Button = ({ text, classes, ...rest }: ButtonProps) => {
  return (
    <button 
      {...rest} 
      className={classes == null ? 'default' : classes}>
      {text}
    </button>
  )
}
export default Button;