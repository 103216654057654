import './search.css';
import { useState, useCallback, InputHTMLAttributes, useEffect } from 'react';
import Spinner from '../spinner/spinner';

interface SearchProps extends InputHTMLAttributes<HTMLInputElement> {
  classes?: string,
  onSelect: (value: any) => void,
  values: string,
  fetchService: (item: any) => Promise<any>
}

const Search = ({ values, classes, onSelect, fetchService, ...rest }: SearchProps) => {

  const [value, setValue] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false)
  const [onChange, setOnChange] = useState(false)

  const handleSelect = (result: any, selectedValue: string) => {
    setResults([])
    setOnChange(false)
    setValue(selectedValue);
    onSelect(result);
  };

  const handleChange = (event: any) => {
    setOnChange(true)
    setValue(event.target.value);
  };

  const obtemListaCallback  = useCallback(async (item: any): Promise<any> => {
    setResults([])
    var result = await fetchService(item)
    if (result.content.length > 0) {
      setResults(result.content)
    }
    setLoading(false)
  }, [fetchService])

  useEffect(() => {
    if (!value || !onChange)
      return;

    const timer = setTimeout(() => {
      setLoading(true)
      obtemListaCallback (value)
    }, 2000);
    return () => clearTimeout(timer);
  }, [value, onChange, obtemListaCallback ]);

  return (
    <div>
      <input
        {...rest}
        type="text"
        className={classes}
        value={value}
        onChange={x => handleChange(x)}
        placeholder="Digite para buscar..."
      />
      {results.length > 0 && <ul className='input-search'>
        {results.map((result, index) => (
          <li key={index} onClick={() => handleSelect(result, result[values])}>{result[values]}</li>
        ))}
      </ul>}
      {loading && <div className='loading'>
        <Spinner size={170} />
      </div>}
    </div>
  );
};

export default Search;
