class ErroDeNegocio extends Error {
  readonly codigo: string;
  readonly erros?: { codigo: string; mensagem: string }[];

  constructor(readonly erro: { codigo: string, mensagem: string, erros?: { codigo: string; mensagem: string }[] }) {
    super(erro.mensagem);
    this.codigo = erro.codigo;
    this.erros = erro.erros;
  }

  get mensagem():string {
    return `${this.erros?.reduce((prev, curr) => `${prev}\u2022 ${curr.mensagem}\n`, '') || this.message}`;
  };
}

export default ErroDeNegocio;