import { signOut } from 'aws-amplify/auth';
import { useCallback, useContext } from 'react';
import SessaoUsuarioContext from '../contexts/SessaoUsuarioContext';
import UsuarioLocalStorageService from '../services/UsuarioLocalStorageService';

export default function useSignOut() {
  
  const { setSessao } = useContext(SessaoUsuarioContext); 

  const signOutCallback = useCallback(async () => {
    UsuarioLocalStorageService.remover();
    
    await signOut();
    setSessao!(undefined);

  }, [setSessao]);

  return signOutCallback;
}
