import { CSSProperties } from "react";

const NotificacaoDeAcessoNegadoAoModulo = () => {
  const styles: { [key: string]: CSSProperties } = {
    container: {
      justifyContent: 'center',
      display: 'flex',
      textAlign: 'center',
      marginTop: '30px'
    },
    notificacao: {
      width: '800px',
      background: '#fff',
      color: '#6C7381',
      borderRadius: '12px',
      padding: '30px',
      boxShadow: '0px 4px 7px 0px #0000001A'
    },
    h4: { margin: 0 }
  };

  return <>
    <div style={styles.container}>
      <div style={styles.notificacao}>
        <h4 style={styles.h4}>
          Você ainda não possui acesso a este módulo.<br /><br />
          Solicite acesso ao administrador do sistema na sua empresa,<br />ou fale com o nosso time comercial caso ainda não o tenha adquirido.
        </h4>
      </div>
    </div>
  </>;
}

export default NotificacaoDeAcessoNegadoAoModulo;