import MarkDown from '../../components/markdown/markdown';
import Voltar from '../../components/voltar/voltar';
import markdownFile from '../../versao/historico.md';

const HistoricoDeAtualizacoes = () => {
  return (
    <>
      <h1 className="top-title"><Voltar />Histórico de Versões</h1>
      <div className="content-container">
        <MarkDown urlArquivo={markdownFile} />
      </div>
    </>
  )
}
export default HistoricoDeAtualizacoes;