import Button from "../../../components/button/button";
import Spinner from "../../../components/spinner/spinner";
import { HtmlHTMLAttributes, useCallback, useEffect, useState } from "react";
import { EmpresaInput } from "../../../services/EmpresaService";
import Feedback from "../../../components/feedback/feedback";
import useAppFeedback from "../../../hooks/use-app-feedback";

import '../../shared/formulario.css'

interface EmpresaFormProps extends HtmlHTMLAttributes<HTMLElement> {
  classes?: string,
  loading: boolean;
  titulo?: string;
  empresaInput?: EmpresaInput;
  acao: {
    onClickAcao?: (value: EmpresaInput) => Promise<boolean>,
    texto: string
  };
  cancelarAcao: {
    onClickCancelaAcao?: () => void
    texto: string
  }
}

const EmpresaForm = ({ loading, acao, cancelarAcao, classes, titulo, empresaInput }: EmpresaFormProps) => {

  const { feedbacks, removerFeedback } = useAppFeedback();
  
  const [nome, setNome] = useState("");
  const [codigo, setCodigo] = useState("");
  const [empresa, setEmpresa] = useState({});

  const clearForm = useCallback(() => {
    setCodigo("");
    setNome("");
  }, []);

  useEffect(() => {
    const empresa = {
      id: empresaInput?.id,
      nome: nome,
      codigo: codigo
    }
    setEmpresa(empresa)
  }, [nome, codigo, empresaInput])

  useEffect(() => {
    setCodigo(empresaInput?.codigo.toString() ?? "");
    setNome(empresaInput?.nome ?? "");
  }, [empresaInput])

  return (
    <>
      <div className={classes ?? 'formulario'}>
        {titulo && <h1>{titulo}</h1>}
        <Feedback feedbacks={feedbacks} remover={removerFeedback} />
        <label>Preencha os campos abaixo para criar uma nova empresa</label>
        <div className='formulario__corpo'>
          <label htmlFor="form-empresa-codigo">Código:</label>
          <input
            id="form-empresa-codigo"
            className='formulario__corpo--input'
            onChange={x => setCodigo(x.target.value)}
            value={codigo}></input>
          <label htmlFor="form-empresa-nome">Nome:</label>
          <input
            id="form-empresa-nome"
            className='formulario__corpo--input'
            onChange={x => setNome(x.target.value)}
            value={nome}></input>
        </div>
        <Button
          classes='formulario__button'
          style={{ margin: 0 }}
          text={acao.texto}
          onClick={async () => acao.onClickAcao && await acao.onClickAcao(empresa as EmpresaInput) && clearForm()} />
        <Button
          classes='formulario__button'
          style={{ marginLeft: 8 }}
          text={cancelarAcao.texto}
          onClick={() => cancelarAcao.onClickCancelaAcao && cancelarAcao.onClickCancelaAcao()} />
      </div>
      {
        loading && <div className='loading'>
          <Spinner size={170} />
        </div>
      }
    </>
  );
}

export default EmpresaForm;