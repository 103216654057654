import { CSSProperties, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import AppContext from "../../contexts/AppContext";
import AmbienteService, { ObtencaoDePainelOutput } from "../../services/AmbienteService";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import Spinner from "../../components/spinner/spinner";
import PowerBITokenService from "../../services/PowerBITokenService";
import Voltar from "../../components/voltar/voltar";

import './Painel.css';

const Painel = () => {
  const { modulo, parametroNomePainel } = useParams();
  const [painelId, setPainelId] = useState("");
  const { ambiente: { espacoDeTrabalhoId, paineis } } = useContext(AppContext);
  const [carregando, setCarregando] = useState(true);
  const [painel, setPainel] = useState<ObtencaoDePainelOutput>();
  const [intervaloParaRenovar, setIntervaloParaRenovar] = useState<number | undefined>();
  const nomePainel = useMemo(() => paineis?.filter(r => r.id === painelId)[0]?.nome, [paineis, painelId]);
  const [status, setStatus] = useState(true);

  useEffect(() => {
    if (paineis && paineis.length > 0) {
      var painel = paineis.find(x => x.nome === parametroNomePainel)?.id.toString() ?? "";
      setPainelId(painel)
    }
  }, [paineis, parametroNomePainel])

  useEffect(() => {
    (async () => {
      if (espacoDeTrabalhoId && painelId) {
        setCarregando(true);
        const output = await AmbienteService.obterPainel(espacoDeTrabalhoId, painelId);
        setPainel(output);
        setCarregando(false);
      }
    })();
  }, [espacoDeTrabalhoId, painelId]);

  const renovarToken = useCallback(() => {
    (async () => {
      if (!painel) return;

      const token = (await AmbienteService.obterPainel(painel.espacoDeTrabalhoId!, painel.painelId!)).chaveDeAcesso;
      await (window as any).report.setAccessToken(token);
    })()
  }, [painel]);

  useEffect(() => {
    if (!painel) return;

    const expiracao = PowerBITokenService.parse(painel.chaveDeAcesso!).exp * 1000;
    const agora = Date.now();
    const tempoAteExpirar = expiracao - agora;
    const doisMinutos = 2 * 60000;
    setIntervaloParaRenovar(tempoAteExpirar - doisMinutos);
  }, [painel]);

  useEffect(() => {
    if (!intervaloParaRenovar) return;

    const intervalId = setInterval(renovarToken, intervaloParaRenovar);
    return () => clearInterval(intervalId);
  }, [renovarToken, intervaloParaRenovar]);

  return <>
    <div className="top-title"><Voltar caminho={`modulo/${modulo}`} /> <img className="painel__iconImg" src={`/${nomePainel}.png`} alt='' /><h1>{nomePainel}</h1></div>
    {
      carregando && <section className="carregando-container">
        <Spinner size={170} />
      </section>
    }
    {
      painel && !carregando && <div className="painel-container">
        {status &&
          <PowerBIEmbed
            embedConfig={{
              type: 'report',
              id: painelId ?? '',
              embedUrl: painel.urlEmbutida ?? '',
              accessToken: painel.chaveDeAcesso ?? '',
              tokenType: models.TokenType.Embed,
              settings: {
                localeSettings: {
                  language: 'pt-BR',
                  formatLocale: 'pt-BR'
                },
                panes: {
                  filters: {
                    expanded: false,
                    visible: false
                  }
                },
                background: models.BackgroundType.Transparent,
                zoomLevel: 1.20
              }
            }}

            eventHandlers={
              new Map([
                ['error', function (event) {
                  var erros = event?.detail['technicalDetails']['errorInfo']
                  const errorDetails = erros?.find((erro: any) => erro.key === "errorDetails" && erro.value.includes("CapacityNotActive"));

                  if (errorDetails)
                    setStatus(false);
                }],
              ])
            }

            cssClassName={"painel"}
            getEmbeddedComponent={(embeddedReport) => {
              const _window: any = window;
              _window.report = embeddedReport;
            }}
          />
        }
        {!status &&
          <>
            <div style={styles.container}>
              <div style={styles.notificacao}>
                <h4 style={styles.h4}>
                  Sistema temporariamente indisponível.<br /><br />
                  Temporariamente sem acesso ao relatório devido ao serviço de manutenção agendada.<br /> Volte mais tarde para continuar.
                </h4>
              </div>
            </div>
          </>
        }
      </div>
    }
  </>
}

export default Painel;

const styles: { [key: string]: CSSProperties } = {
  container: {
    justifyContent: 'center',
    display: 'flex',
    textAlign: 'center',
    marginTop: '30px'
  },
  notificacao: {
    width: '800px',
    background: '#fff',
    color: '#6C7381',
    borderRadius: '12px',
    padding: '30px',
    boxShadow: '0px 4px 7px 0px #0000001A'
  },
  h4: { margin: 0 }
};