import { createContext } from 'react';
import { UsuarioDoLicenciadoOutput } from '../services/UsuarioService';

interface SessaoUsuarioContextProps {
  usuariosLicenciados: UsuarioDoLicenciadoOutput[];
  sessao?: UsuarioDoLicenciadoOutput | null;
  setSessao?: (sessao?: UsuarioDoLicenciadoOutput | null) => void;
}

const SessaoUsuarioContext = createContext<SessaoUsuarioContextProps>({
  usuariosLicenciados: [],
  sessao: null,
  setSessao: () => { }
});

export default SessaoUsuarioContext;