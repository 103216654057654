import FBIHttp from "./FBIHttp";

interface ObtencaoDeEmpresasOutput {
  sucesso: boolean,
  content: {
    empresas: EmpresaOutput[]
  }
}

export interface EmpresaOutput {
  id: string,
  codigo: string,
  nome: string
}

export interface EmpresaInput {
  id: string,
  codigo: string,
  nome: string
}

export interface EmpresaDeleteInput {
  EmpresaId: string
}

class EmpresaService {
  public static obterEmpresas = async (): Promise<ObtencaoDeEmpresasOutput> => FBIHttp.get('/api/bi/Empresa');
  public static salvarEmpresa = async (input: EmpresaInput): Promise<ObtencaoDeEmpresasOutput> => FBIHttp.post('/api/bi/Empresa', input);
  public static removerEmpresa = async (input: EmpresaDeleteInput): Promise<ObtencaoDeEmpresasOutput> => FBIHttp.delete('/api/bi/Empresa/' + input.EmpresaId);
}

export default EmpresaService;